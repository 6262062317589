import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _10884d6b = () => interopDefault(import('../pages/corporate.vue' /* webpackChunkName: "pages/corporate" */))
const _0a21e0df = () => interopDefault(import('../pages/embed.vue' /* webpackChunkName: "pages/embed" */))
const _175fa654 = () => interopDefault(import('../pages/naha.vue' /* webpackChunkName: "pages/naha" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6c9b9fbc = () => interopDefault(import('../pages/search/city/_brief.vue' /* webpackChunkName: "pages/search/city/_brief" */))
const _d593c09e = () => interopDefault(import('../pages/about/cancelTerms.vue' /* webpackChunkName: "pages/about/cancelTerms" */))
const _7461931a = () => interopDefault(import('../pages/about/faq.vue' /* webpackChunkName: "pages/about/faq" */))
const _5b31635e = () => interopDefault(import('../pages/about/gogoout_promise.vue' /* webpackChunkName: "pages/about/gogoout_promise" */))
const _4e1f601d = () => interopDefault(import('../pages/about/omnichat.vue' /* webpackChunkName: "pages/about/omnichat" */))
const _337b209f = () => interopDefault(import('../pages/about/promise.vue' /* webpackChunkName: "pages/about/promise" */))
const _74482542 = () => interopDefault(import('../pages/about/recruit.vue' /* webpackChunkName: "pages/about/recruit" */))
const _1620976c = () => interopDefault(import('../pages/about/us.vue' /* webpackChunkName: "pages/about/us" */))
const _75c9fe29 = () => interopDefault(import('../pages/car/questionnaireReturnCar/index.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/index" */))
const _06cc3a2d = () => interopDefault(import('../pages/car/search.vue' /* webpackChunkName: "pages/car/search" */))
const _49454ade = () => interopDefault(import('../pages/car/searchHertz.vue' /* webpackChunkName: "pages/car/searchHertz" */))
const _4767d109 = () => interopDefault(import('../pages/car/searchJapan.vue' /* webpackChunkName: "pages/car/searchJapan" */))
const _054673bc = () => interopDefault(import('../pages/member/account.vue' /* webpackChunkName: "pages/member/account" */))
const _56808ebd = () => interopDefault(import('../pages/member/check.vue' /* webpackChunkName: "pages/member/check" */))
const _8bb07d76 = () => interopDefault(import('../pages/member/commercial-invoice.vue' /* webpackChunkName: "pages/member/commercial-invoice" */))
const _4f76fec4 = () => interopDefault(import('../pages/member/login.vue' /* webpackChunkName: "pages/member/login" */))
const _27679e03 = () => interopDefault(import('../pages/member/order.vue' /* webpackChunkName: "pages/member/order" */))
const _17b7f638 = () => interopDefault(import('../pages/member/rating.vue' /* webpackChunkName: "pages/member/rating" */))
const _914168f8 = () => interopDefault(import('../pages/member/reset.vue' /* webpackChunkName: "pages/member/reset" */))
const _7ca58033 = () => interopDefault(import('../pages/member/signup.vue' /* webpackChunkName: "pages/member/signup" */))
const _764e5f11 = () => interopDefault(import('../pages/car/questionnaireReturnCar/appreciate.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/appreciate" */))
const _3d588de4 = () => interopDefault(import('../pages/car/questionnaireReturnCar/invalid.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/invalid" */))
const _1657e7fd = () => interopDefault(import('../pages/car/questionnaireReturnCar/withoutLogin/index.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/withoutLogin/index" */))
const _0f1c0e75 = () => interopDefault(import('../pages/member/apple/callback.vue' /* webpackChunkName: "pages/member/apple/callback" */))
const _18b45cf0 = () => interopDefault(import('../pages/member/apple/error.vue' /* webpackChunkName: "pages/member/apple/error" */))
const _7056e8a3 = () => interopDefault(import('../pages/member/facebook/callback.vue' /* webpackChunkName: "pages/member/facebook/callback" */))
const _3dded61a = () => interopDefault(import('../pages/member/facebook/error.vue' /* webpackChunkName: "pages/member/facebook/error" */))
const _385edb50 = () => interopDefault(import('../pages/member/google/callback.vue' /* webpackChunkName: "pages/member/google/callback" */))
const _128aaa8d = () => interopDefault(import('../pages/member/google/error.vue' /* webpackChunkName: "pages/member/google/error" */))
const _28cd79bd = () => interopDefault(import('../pages/car/questionnaireReturnCar/withoutLogin/appreciate.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/withoutLogin/appreciate" */))
const _d3e3043c = () => interopDefault(import('../pages/car/questionnaireReturnCar/withoutLogin/invalid.vue' /* webpackChunkName: "pages/car/questionnaireReturnCar/withoutLogin/invalid" */))
const _6bccdd94 = () => interopDefault(import('../pages/car/confirm/_id.vue' /* webpackChunkName: "pages/car/confirm/_id" */))
const _9174518e = () => interopDefault(import('../pages/car/confirm/_id/index.vue' /* webpackChunkName: "pages/car/confirm/_id/index" */))
const _01acef22 = () => interopDefault(import('../pages/car/confirm/_id/check.vue' /* webpackChunkName: "pages/car/confirm/_id/check" */))
const _1d6d6bcf = () => interopDefault(import('../pages/car/confirm/_id/pay.vue' /* webpackChunkName: "pages/car/confirm/_id/pay" */))
const _8703abd0 = () => interopDefault(import('../pages/car/confirmHertz/_vendor.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor" */))
const _95ce82ca = () => interopDefault(import('../pages/car/confirmHertz/_vendor/index.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/index" */))
const _0607205e = () => interopDefault(import('../pages/car/confirmHertz/_vendor/check.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/check" */))
const _af92789e = () => interopDefault(import('../pages/car/confirmHertz/_vendor/pay.vue' /* webpackChunkName: "pages/car/confirmHertz/_vendor/pay" */))
const _d17a75d4 = () => interopDefault(import('../pages/car/confirmJapan/_id.vue' /* webpackChunkName: "pages/car/confirmJapan/_id" */))
const _68f0a319 = () => interopDefault(import('../pages/car/confirmJapan/_id/index.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/index" */))
const _9e575762 = () => interopDefault(import('../pages/car/confirmJapan/_id/check.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/check" */))
const _0919afaf = () => interopDefault(import('../pages/car/confirmJapan/_id/pay.vue' /* webpackChunkName: "pages/car/confirmJapan/_id/pay" */))
const _1d31621e = () => interopDefault(import('../pages/car/order_result/_id.vue' /* webpackChunkName: "pages/car/order_result/_id" */))
const _ccd4a83e = () => interopDefault(import('../pages/suggestion/country/_country.vue' /* webpackChunkName: "pages/suggestion/country/_country" */))
const _dc1632e4 = () => interopDefault(import('../pages/about/_slug.vue' /* webpackChunkName: "pages/about/_slug" */))
const _61ee7752 = () => interopDefault(import('../pages/collection/_type.vue' /* webpackChunkName: "pages/collection/_type" */))
const _550045e0 = () => interopDefault(import('../pages/comment/_id.vue' /* webpackChunkName: "pages/comment/_id" */))
const _5c4a6ddc = () => interopDefault(import('../pages/store/_id.vue' /* webpackChunkName: "pages/store/_id" */))
const _95a8d62e = () => interopDefault(import('../pages/suggestion/_city.vue' /* webpackChunkName: "pages/suggestion/_city" */))
const _4c34ebaa = () => interopDefault(import('../pages/androiddeeplink/_.vue' /* webpackChunkName: "pages/androiddeeplink/_" */))
const _160d4356 = () => interopDefault(import('../pages/appledeeplink/_.vue' /* webpackChunkName: "pages/appledeeplink/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/ja",
    component: _2dfb1658,
    name: "index___ja"
  }, {
    path: "/zh-tw",
    component: _2dfb1658,
    name: "index___zh-tw"
  }, {
    path: "/en/corporate",
    component: _10884d6b,
    name: "corporate___en"
  }, {
    path: "/en/embed",
    component: _0a21e0df,
    name: "embed___en"
  }, {
    path: "/en/naha",
    component: _175fa654,
    name: "naha___en"
  }, {
    path: "/en/register",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/en/search",
    component: _3d268e12,
    name: "search___en",
    children: [{
      path: "city/:brief?",
      component: _6c9b9fbc,
      name: "search-city-brief___en"
    }]
  }, {
    path: "/ja/corporate",
    component: _10884d6b,
    name: "corporate___ja"
  }, {
    path: "/ja/embed",
    component: _0a21e0df,
    name: "embed___ja"
  }, {
    path: "/ja/naha",
    component: _175fa654,
    name: "naha___ja"
  }, {
    path: "/ja/register",
    component: _5fba350d,
    name: "register___ja"
  }, {
    path: "/ja/search",
    component: _3d268e12,
    name: "search___ja",
    children: [{
      path: "city/:brief?",
      component: _6c9b9fbc,
      name: "search-city-brief___ja"
    }]
  }, {
    path: "/zh-tw/corporate",
    component: _10884d6b,
    name: "corporate___zh-tw"
  }, {
    path: "/zh-tw/embed",
    component: _0a21e0df,
    name: "embed___zh-tw"
  }, {
    path: "/zh-tw/naha",
    component: _175fa654,
    name: "naha___zh-tw"
  }, {
    path: "/zh-tw/register",
    component: _5fba350d,
    name: "register___zh-tw"
  }, {
    path: "/zh-tw/search",
    component: _3d268e12,
    name: "search___zh-tw",
    children: [{
      path: "city/:brief?",
      component: _6c9b9fbc,
      name: "search-city-brief___zh-tw"
    }]
  }, {
    path: "/en/about/cancelTerms",
    component: _d593c09e,
    name: "about-cancelTerms___en"
  }, {
    path: "/en/about/faq",
    component: _7461931a,
    name: "about-faq___en"
  }, {
    path: "/en/about/gogoout_promise",
    component: _5b31635e,
    name: "about-gogoout_promise___en"
  }, {
    path: "/en/about/omnichat",
    component: _4e1f601d,
    name: "about-omnichat___en"
  }, {
    path: "/en/about/promise",
    component: _337b209f,
    name: "about-promise___en"
  }, {
    path: "/en/about/recruit",
    component: _74482542,
    name: "about-recruit___en"
  }, {
    path: "/en/about/us",
    component: _1620976c,
    name: "about-us___en"
  }, {
    path: "/en/car/questionnaireReturnCar",
    component: _75c9fe29,
    name: "car-questionnaireReturnCar___en"
  }, {
    path: "/en/car/search",
    component: _06cc3a2d,
    name: "car-search___en"
  }, {
    path: "/en/car/searchHertz",
    component: _49454ade,
    name: "car-searchHertz___en"
  }, {
    path: "/en/car/searchJapan",
    component: _4767d109,
    name: "car-searchJapan___en"
  }, {
    path: "/en/member/account",
    component: _054673bc,
    name: "member-account___en"
  }, {
    path: "/en/member/check",
    component: _56808ebd,
    name: "member-check___en"
  }, {
    path: "/en/member/commercial-invoice",
    component: _8bb07d76,
    name: "member-commercial-invoice___en"
  }, {
    path: "/en/member/login",
    component: _4f76fec4,
    name: "member-login___en"
  }, {
    path: "/en/member/order",
    component: _27679e03,
    name: "member-order___en"
  }, {
    path: "/en/member/rating",
    component: _17b7f638,
    name: "member-rating___en"
  }, {
    path: "/en/member/reset",
    component: _914168f8,
    name: "member-reset___en"
  }, {
    path: "/en/member/signup",
    component: _7ca58033,
    name: "member-signup___en"
  }, {
    path: "/ja/about/cancelTerms",
    component: _d593c09e,
    name: "about-cancelTerms___ja"
  }, {
    path: "/ja/about/faq",
    component: _7461931a,
    name: "about-faq___ja"
  }, {
    path: "/ja/about/gogoout_promise",
    component: _5b31635e,
    name: "about-gogoout_promise___ja"
  }, {
    path: "/ja/about/omnichat",
    component: _4e1f601d,
    name: "about-omnichat___ja"
  }, {
    path: "/ja/about/promise",
    component: _337b209f,
    name: "about-promise___ja"
  }, {
    path: "/ja/about/recruit",
    component: _74482542,
    name: "about-recruit___ja"
  }, {
    path: "/ja/about/us",
    component: _1620976c,
    name: "about-us___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar",
    component: _75c9fe29,
    name: "car-questionnaireReturnCar___ja"
  }, {
    path: "/ja/car/search",
    component: _06cc3a2d,
    name: "car-search___ja"
  }, {
    path: "/ja/car/searchHertz",
    component: _49454ade,
    name: "car-searchHertz___ja"
  }, {
    path: "/ja/car/searchJapan",
    component: _4767d109,
    name: "car-searchJapan___ja"
  }, {
    path: "/ja/member/account",
    component: _054673bc,
    name: "member-account___ja"
  }, {
    path: "/ja/member/check",
    component: _56808ebd,
    name: "member-check___ja"
  }, {
    path: "/ja/member/commercial-invoice",
    component: _8bb07d76,
    name: "member-commercial-invoice___ja"
  }, {
    path: "/ja/member/login",
    component: _4f76fec4,
    name: "member-login___ja"
  }, {
    path: "/ja/member/order",
    component: _27679e03,
    name: "member-order___ja"
  }, {
    path: "/ja/member/rating",
    component: _17b7f638,
    name: "member-rating___ja"
  }, {
    path: "/ja/member/reset",
    component: _914168f8,
    name: "member-reset___ja"
  }, {
    path: "/ja/member/signup",
    component: _7ca58033,
    name: "member-signup___ja"
  }, {
    path: "/zh-tw/about/cancelTerms",
    component: _d593c09e,
    name: "about-cancelTerms___zh-tw"
  }, {
    path: "/zh-tw/about/faq",
    component: _7461931a,
    name: "about-faq___zh-tw"
  }, {
    path: "/zh-tw/about/gogoout_promise",
    component: _5b31635e,
    name: "about-gogoout_promise___zh-tw"
  }, {
    path: "/zh-tw/about/omnichat",
    component: _4e1f601d,
    name: "about-omnichat___zh-tw"
  }, {
    path: "/zh-tw/about/promise",
    component: _337b209f,
    name: "about-promise___zh-tw"
  }, {
    path: "/zh-tw/about/recruit",
    component: _74482542,
    name: "about-recruit___zh-tw"
  }, {
    path: "/zh-tw/about/us",
    component: _1620976c,
    name: "about-us___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar",
    component: _75c9fe29,
    name: "car-questionnaireReturnCar___zh-tw"
  }, {
    path: "/zh-tw/car/search",
    component: _06cc3a2d,
    name: "car-search___zh-tw"
  }, {
    path: "/zh-tw/car/searchHertz",
    component: _49454ade,
    name: "car-searchHertz___zh-tw"
  }, {
    path: "/zh-tw/car/searchJapan",
    component: _4767d109,
    name: "car-searchJapan___zh-tw"
  }, {
    path: "/zh-tw/member/account",
    component: _054673bc,
    name: "member-account___zh-tw"
  }, {
    path: "/zh-tw/member/check",
    component: _56808ebd,
    name: "member-check___zh-tw"
  }, {
    path: "/zh-tw/member/commercial-invoice",
    component: _8bb07d76,
    name: "member-commercial-invoice___zh-tw"
  }, {
    path: "/zh-tw/member/login",
    component: _4f76fec4,
    name: "member-login___zh-tw"
  }, {
    path: "/zh-tw/member/order",
    component: _27679e03,
    name: "member-order___zh-tw"
  }, {
    path: "/zh-tw/member/rating",
    component: _17b7f638,
    name: "member-rating___zh-tw"
  }, {
    path: "/zh-tw/member/reset",
    component: _914168f8,
    name: "member-reset___zh-tw"
  }, {
    path: "/zh-tw/member/signup",
    component: _7ca58033,
    name: "member-signup___zh-tw"
  }, {
    path: "/en/car/questionnaireReturnCar/appreciate",
    component: _764e5f11,
    name: "car-questionnaireReturnCar-appreciate___en"
  }, {
    path: "/en/car/questionnaireReturnCar/invalid",
    component: _3d588de4,
    name: "car-questionnaireReturnCar-invalid___en"
  }, {
    path: "/en/car/questionnaireReturnCar/withoutLogin",
    component: _1657e7fd,
    name: "car-questionnaireReturnCar-withoutLogin___en"
  }, {
    path: "/en/member/apple/callback",
    component: _0f1c0e75,
    name: "member-apple-callback___en"
  }, {
    path: "/en/member/apple/error",
    component: _18b45cf0,
    name: "member-apple-error___en"
  }, {
    path: "/en/member/facebook/callback",
    component: _7056e8a3,
    name: "member-facebook-callback___en"
  }, {
    path: "/en/member/facebook/error",
    component: _3dded61a,
    name: "member-facebook-error___en"
  }, {
    path: "/en/member/google/callback",
    component: _385edb50,
    name: "member-google-callback___en"
  }, {
    path: "/en/member/google/error",
    component: _128aaa8d,
    name: "member-google-error___en"
  }, {
    path: "/ja/car/questionnaireReturnCar/appreciate",
    component: _764e5f11,
    name: "car-questionnaireReturnCar-appreciate___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar/invalid",
    component: _3d588de4,
    name: "car-questionnaireReturnCar-invalid___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar/withoutLogin",
    component: _1657e7fd,
    name: "car-questionnaireReturnCar-withoutLogin___ja"
  }, {
    path: "/ja/member/apple/callback",
    component: _0f1c0e75,
    name: "member-apple-callback___ja"
  }, {
    path: "/ja/member/apple/error",
    component: _18b45cf0,
    name: "member-apple-error___ja"
  }, {
    path: "/ja/member/facebook/callback",
    component: _7056e8a3,
    name: "member-facebook-callback___ja"
  }, {
    path: "/ja/member/facebook/error",
    component: _3dded61a,
    name: "member-facebook-error___ja"
  }, {
    path: "/ja/member/google/callback",
    component: _385edb50,
    name: "member-google-callback___ja"
  }, {
    path: "/ja/member/google/error",
    component: _128aaa8d,
    name: "member-google-error___ja"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/appreciate",
    component: _764e5f11,
    name: "car-questionnaireReturnCar-appreciate___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/invalid",
    component: _3d588de4,
    name: "car-questionnaireReturnCar-invalid___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/withoutLogin",
    component: _1657e7fd,
    name: "car-questionnaireReturnCar-withoutLogin___zh-tw"
  }, {
    path: "/zh-tw/member/apple/callback",
    component: _0f1c0e75,
    name: "member-apple-callback___zh-tw"
  }, {
    path: "/zh-tw/member/apple/error",
    component: _18b45cf0,
    name: "member-apple-error___zh-tw"
  }, {
    path: "/zh-tw/member/facebook/callback",
    component: _7056e8a3,
    name: "member-facebook-callback___zh-tw"
  }, {
    path: "/zh-tw/member/facebook/error",
    component: _3dded61a,
    name: "member-facebook-error___zh-tw"
  }, {
    path: "/zh-tw/member/google/callback",
    component: _385edb50,
    name: "member-google-callback___zh-tw"
  }, {
    path: "/zh-tw/member/google/error",
    component: _128aaa8d,
    name: "member-google-error___zh-tw"
  }, {
    path: "/en/car/questionnaireReturnCar/withoutLogin/appreciate",
    component: _28cd79bd,
    name: "car-questionnaireReturnCar-withoutLogin-appreciate___en"
  }, {
    path: "/en/car/questionnaireReturnCar/withoutLogin/invalid",
    component: _d3e3043c,
    name: "car-questionnaireReturnCar-withoutLogin-invalid___en"
  }, {
    path: "/ja/car/questionnaireReturnCar/withoutLogin/appreciate",
    component: _28cd79bd,
    name: "car-questionnaireReturnCar-withoutLogin-appreciate___ja"
  }, {
    path: "/ja/car/questionnaireReturnCar/withoutLogin/invalid",
    component: _d3e3043c,
    name: "car-questionnaireReturnCar-withoutLogin-invalid___ja"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/withoutLogin/appreciate",
    component: _28cd79bd,
    name: "car-questionnaireReturnCar-withoutLogin-appreciate___zh-tw"
  }, {
    path: "/zh-tw/car/questionnaireReturnCar/withoutLogin/invalid",
    component: _d3e3043c,
    name: "car-questionnaireReturnCar-withoutLogin-invalid___zh-tw"
  }, {
    path: "/en/car/confirm/:id?",
    component: _6bccdd94,
    children: [{
      path: "",
      component: _9174518e,
      name: "car-confirm-id___en"
    }, {
      path: "check",
      component: _01acef22,
      name: "car-confirm-id-check___en"
    }, {
      path: "pay",
      component: _1d6d6bcf,
      name: "car-confirm-id-pay___en"
    }]
  }, {
    path: "/en/car/confirmHertz/:vendor?",
    component: _8703abd0,
    children: [{
      path: "",
      component: _95ce82ca,
      name: "car-confirmHertz-vendor___en"
    }, {
      path: "check",
      component: _0607205e,
      name: "car-confirmHertz-vendor-check___en"
    }, {
      path: "pay",
      component: _af92789e,
      name: "car-confirmHertz-vendor-pay___en"
    }]
  }, {
    path: "/en/car/confirmJapan/:id?",
    component: _d17a75d4,
    children: [{
      path: "",
      component: _68f0a319,
      name: "car-confirmJapan-id___en"
    }, {
      path: "check",
      component: _9e575762,
      name: "car-confirmJapan-id-check___en"
    }, {
      path: "pay",
      component: _0919afaf,
      name: "car-confirmJapan-id-pay___en"
    }]
  }, {
    path: "/en/car/order_result/:id?",
    component: _1d31621e,
    name: "car-order_result-id___en"
  }, {
    path: "/en/suggestion/country/:country?",
    component: _ccd4a83e,
    name: "suggestion-country-country___en"
  }, {
    path: "/ja/car/confirm/:id?",
    component: _6bccdd94,
    children: [{
      path: "",
      component: _9174518e,
      name: "car-confirm-id___ja"
    }, {
      path: "check",
      component: _01acef22,
      name: "car-confirm-id-check___ja"
    }, {
      path: "pay",
      component: _1d6d6bcf,
      name: "car-confirm-id-pay___ja"
    }]
  }, {
    path: "/ja/car/confirmHertz/:vendor?",
    component: _8703abd0,
    children: [{
      path: "",
      component: _95ce82ca,
      name: "car-confirmHertz-vendor___ja"
    }, {
      path: "check",
      component: _0607205e,
      name: "car-confirmHertz-vendor-check___ja"
    }, {
      path: "pay",
      component: _af92789e,
      name: "car-confirmHertz-vendor-pay___ja"
    }]
  }, {
    path: "/ja/car/confirmJapan/:id?",
    component: _d17a75d4,
    children: [{
      path: "",
      component: _68f0a319,
      name: "car-confirmJapan-id___ja"
    }, {
      path: "check",
      component: _9e575762,
      name: "car-confirmJapan-id-check___ja"
    }, {
      path: "pay",
      component: _0919afaf,
      name: "car-confirmJapan-id-pay___ja"
    }]
  }, {
    path: "/ja/car/order_result/:id?",
    component: _1d31621e,
    name: "car-order_result-id___ja"
  }, {
    path: "/ja/suggestion/country/:country?",
    component: _ccd4a83e,
    name: "suggestion-country-country___ja"
  }, {
    path: "/zh-tw/car/confirm/:id?",
    component: _6bccdd94,
    children: [{
      path: "",
      component: _9174518e,
      name: "car-confirm-id___zh-tw"
    }, {
      path: "check",
      component: _01acef22,
      name: "car-confirm-id-check___zh-tw"
    }, {
      path: "pay",
      component: _1d6d6bcf,
      name: "car-confirm-id-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/confirmHertz/:vendor?",
    component: _8703abd0,
    children: [{
      path: "",
      component: _95ce82ca,
      name: "car-confirmHertz-vendor___zh-tw"
    }, {
      path: "check",
      component: _0607205e,
      name: "car-confirmHertz-vendor-check___zh-tw"
    }, {
      path: "pay",
      component: _af92789e,
      name: "car-confirmHertz-vendor-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/confirmJapan/:id?",
    component: _d17a75d4,
    children: [{
      path: "",
      component: _68f0a319,
      name: "car-confirmJapan-id___zh-tw"
    }, {
      path: "check",
      component: _9e575762,
      name: "car-confirmJapan-id-check___zh-tw"
    }, {
      path: "pay",
      component: _0919afaf,
      name: "car-confirmJapan-id-pay___zh-tw"
    }]
  }, {
    path: "/zh-tw/car/order_result/:id?",
    component: _1d31621e,
    name: "car-order_result-id___zh-tw"
  }, {
    path: "/zh-tw/suggestion/country/:country?",
    component: _ccd4a83e,
    name: "suggestion-country-country___zh-tw"
  }, {
    path: "/en/about/:slug?",
    component: _dc1632e4,
    name: "about-slug___en"
  }, {
    path: "/en/collection/:type?",
    component: _61ee7752,
    name: "collection-type___en"
  }, {
    path: "/en/comment/:id?",
    component: _550045e0,
    name: "comment-id___en"
  }, {
    path: "/en/store/:id?",
    component: _5c4a6ddc,
    name: "store-id___en"
  }, {
    path: "/en/suggestion/:city?",
    component: _95a8d62e,
    name: "suggestion-city___en"
  }, {
    path: "/ja/about/:slug?",
    component: _dc1632e4,
    name: "about-slug___ja"
  }, {
    path: "/ja/collection/:type?",
    component: _61ee7752,
    name: "collection-type___ja"
  }, {
    path: "/ja/comment/:id?",
    component: _550045e0,
    name: "comment-id___ja"
  }, {
    path: "/ja/store/:id?",
    component: _5c4a6ddc,
    name: "store-id___ja"
  }, {
    path: "/ja/suggestion/:city?",
    component: _95a8d62e,
    name: "suggestion-city___ja"
  }, {
    path: "/zh-tw/about/:slug?",
    component: _dc1632e4,
    name: "about-slug___zh-tw"
  }, {
    path: "/zh-tw/collection/:type?",
    component: _61ee7752,
    name: "collection-type___zh-tw"
  }, {
    path: "/zh-tw/comment/:id?",
    component: _550045e0,
    name: "comment-id___zh-tw"
  }, {
    path: "/zh-tw/store/:id?",
    component: _5c4a6ddc,
    name: "store-id___zh-tw"
  }, {
    path: "/zh-tw/suggestion/:city?",
    component: _95a8d62e,
    name: "suggestion-city___zh-tw"
  }, {
    path: "/ja/androiddeeplink/*",
    component: _4c34ebaa,
    name: "androiddeeplink-all___ja"
  }, {
    path: "/en/androiddeeplink/*",
    component: _4c34ebaa,
    name: "androiddeeplink-all___en"
  }, {
    path: "/zh-tw/androiddeeplink/*",
    component: _4c34ebaa,
    name: "androiddeeplink-all___zh-tw"
  }, {
    path: "/ja/appledeeplink/*",
    component: _160d4356,
    name: "appledeeplink-all___ja"
  }, {
    path: "/en/appledeeplink/*",
    component: _160d4356,
    name: "appledeeplink-all___en"
  }, {
    path: "/zh-tw/appledeeplink/*",
    component: _160d4356,
    name: "appledeeplink-all___zh-tw"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
