




import presets from '@/assets/constants/sih-presets'
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    /** 圖片的 src 屬性 */
    src: {
      type: String,
      required: false,
    },
    /** 圖片的渲染寬度，單位為 px，避免 CLS */
    width: {
      type: Number,
      required: false,
    },
    /** 圖片的渲染高度，單位為 px，避免 CLS */
    height: {
      type: Number,
      required: false,
    },
    /** 圖片的 preset 屬性 */
    preset: {
      type: String,
      required: false,
    },
  },
  setup (props) {
    const sihPreset = computed(() => {
      return presets.find(_preset => _preset.name === props.preset)
    })

    const imageSrc = computed(() => {
      if (!props.src) return props.src

      // 如果不是 S3 CDN 或 S3 endpoint 開頭的 url，直接回傳
      if (!props.src.startsWith(process.env.S3_CDN_ENDPOINT!) && !props.src.startsWith(process.env.S3_ENDPOINT!)) {
        return props.src
      }

      // 如果是 S3 CDN 開頭的 url，持續計算 SIH CDN 的 url
      const w = sihPreset.value?.width || props.width
      const h = sihPreset.value?.height || props.height
      const fit = sihPreset.value?.fit || 'cover'

      // src 開頭去除 S3 CDN url，即為 s3 的 object key
      const s3SrcUrl = new URL(props.src)
      // 去除 pathname 的開頭 "/"
      const objectKey = s3SrcUrl.pathname.slice(1)
      /**
       * 起初 SIH 的 max-age 設定成 1 年，
       * 有些使用者的瀏覽器已經將圖片存至 disk cache，
       * 導致 CDN Invalidate 該圖片，
       * 使用者還是不會更新，
       * 後來將 max-age 設定成 1 天，s-maxage 設定成 1 年，
       * 有些既有圖片可以透過加 query string 去更新 disk cache，
       * 因此將這些 query string 也帶過去 SIH encode 後的 url 強制更新 disk cache
       */
      const s3SrcUrlQuery = s3SrcUrl.searchParams

      const imageRequest = JSON.stringify({
        bucket: process.env.S3_BUCKET_NAME,
        key: objectKey,
        edits: {
          resize: { fit, width: w, height: h },
        },
      })
      const encodedObject = Buffer.from(imageRequest).toString('base64')

      if (s3SrcUrlQuery.size > 0) {
        return `${process.env.SIH_CDN_ENDPOINT}/${encodedObject}?${s3SrcUrlQuery.toString()}`
      }

      return `${process.env.SIH_CDN_ENDPOINT}/${encodedObject}`
    })

    return {
      imageSrc,
    }
  },
})
